import React from 'react'
import Button from 'react-bootstrap'


const NotFound = () => {
    return ( 
        <div>
            <div style={{height: "70vh"}}>
                <div className="text-center display-1" style={{color: "#e9c46a"}}>
                    Error 404!
                    <br/>
                    Page Not found!    
                </div>
            </div>
        </div>
     );
}
 
export default NotFound;